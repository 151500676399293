// import Vue from 'vue'
// import VueRouter from 'vue-router'
import ENV from '../../local_env.json'
import store from '@/stores'
import {checkToken, checkForLogin, setCompanyRoute, SetPreRoute, changeCompany, NewLoginCallBack } from '@/lib/helpers/Token'

/*** 
  解决在路由中添加了和当前路由相同的路由
  重写路由的push方法--->这个是vue-cli4.x以上的坑  
***/ 
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}

// Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'globalHome',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/index.vue')
  },
  {
    path: '/dpjiahome',
    name: 'dpjiahome',
    meta: {
      title: '搭配家'
    },
    component: () => import('@/views/dpjiahome.vue')
  },
  {
    path: '/report/index',
    name: 'report',
    meta: {
      title: '报工系统'
    },
    component: () => import('@/views/report/index.vue')
  },
  {
    path: '/:company_route',
    component: () => import('@/layouts/default.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/hall/index.vue'),
        meta: {
          title: '首页',
          showTab: true
        }
      },
      {
        path: 'backendtransform',
        name: 'backendtransform',
        component: () => import('@/views/backendtransform.vue'),
        meta: {
          title: '销售员管理后台',
          needLogin: true,
          noKeep: true
        }
      },
      {
        path: 'goodslist',
        name: 'search',
        component: () => import('@/views/hall/search.vue'),
        meta: {
          title: '搜索',
          showTab: true,
          noKeep: false
        }
      },
      {
        path: 'furclassify',
        name: 'category',
        component: () => import('@/views/hall/category.vue'),
        meta: {
          title: '全部分类',
          noKeep: true,
          showTab: true
        }
      },
      {
        path: 'furdetail',
        name: 'detail',
        component: () => import('@/views/hall/detail.vue'),
        meta: {
          title: '商品详情',
          noKeep: true
        }
      },
      {
        path: 'cart',
        name: 'cart',
        component: () => import('@/views/hall/cart.vue'),
        meta: {
          title: '购物车',
          showTab: true, // 仅为提高底部
          needLogin: true,
          noKeep: true,
        }
      },
      {
        path: 'confirm',
        name: 'confirm',
        component: () => import('@/views/hall/confirm.vue'),
        meta: {
          title: '确认订单',
          needLogin: true
        }
      },
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/hall/login.vue'),
        meta: {
          title: '登录',
          noKeep: true,
        }
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('@/views/hall/register.vue'),
        meta: {
          title: '注册',
          noKeep: true
        }
      },
      {
        path: 'quicklogin',
        name: 'quicklogin',
        component: () => import('@/views/hall/quicklogin.vue'),
        meta: {
          title: '快捷登录',
          noKeep: true
        }
      },
      {
        path: 'forgetpassword',
        name: 'forgetpassword',
        component: () => import('@/views/hall/forgetpassword.vue'),
        meta: {
          title: '忘记密码',
          noKeep: true
        }
      },
      {
        path: 'person',
        name: 'person',
        component: () => import('@/views/hall/person.vue'),
        meta: {
          title: '个人中心',
          showTab: true,
          needLogin: true,
          noKeep: true
        }
      },
      {
        path: 'personedit',
        name: 'personedit',
        component: () => import('@/views/hall/person_edit.vue'),
        meta: {
          title: '编辑个人资料',
          needLogin: true
        }
      },
      {
        path: 'offer',
        name: 'offer',
        component: () => import('@/views/hall/offer.vue'),
        meta: {
          title: '我的报价单',
          showTab: true,
          needLogin: true,
          noKeep: true
        }
      },
      {
        path: 'offerdetail',
        name: 'offerdetail',
        component: () => import('@/views/hall/offerdetail.vue'),
        meta: {
          title: '报价单详情',
          needLogin: true,
          noKeep: true
        }
      },
      {
        path: 'offerlog',
        name: 'offerlog',
        component: () => import('@/views/hall/offerlog.vue'),
        meta: {
          title: '报价单历史',
          needLogin: true,
          noKeep: true
        }
      },
      {
        path: 'myorder',
        name: 'order',
        component: () => import('@/views/hall/order.vue'),
        meta: {
          title: '我的订单',
          showTab: true,
          needLogin: true,
          noKeep: true
        }
      },
      {
        path: 'orderdetail',
        name: 'orderdetail',
        component: () => import('@/views/hall/orderdetail.vue'),
        meta: {
          title: '订单详情',
          needLogin: true,
          noKeep: true
        }
      },
      {
        path: 'mycollect',
        name: 'collect',
        component: () => import('@/views/hall/collect.vue'),
        meta: {
          title: '我的收藏',
          showTab: true,
          needLogin: true,
        }
      },
      {
        path: 'client',
        name: 'client',
        component: () => import('@/views/hall/client.vue'),
        meta: {
          title: '我的客户',
          showTab: true,
          needLogin: true,
          noKeep: true
        }
      },
      {
        path: 'newclient',
        name: 'newClient',
        component: () => import('@/views/hall/newclient.vue'),
        meta: {
          title: '新建客户',
          needLogin: true,
          noKeep: true
        }
      },
      {
        path: 'customerinfo',
        name: 'customerinfo',
        component: () => import('@/views/hall/customerinfo.vue'),
        meta: {
          title: '客户详情',
          needLogin: true,
          noKeep: true
        }
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('@/views/hall/contact.vue'),
        meta: {
          title: '客户联系人',
          showTab: true,
          needLogin: true
        }
      },
      {
        path: 'contactaddress',
        name: 'contactaddress',
        component: () => import('@/views/hall/contactaddress.vue'),
        meta: {
          title: '联系人地址',
          showTab: true,
          needLogin: true
        }
      },
      {
        path: 'report',
        name: 'project',
        component: () => import('@/views/hall/project.vue'),
        meta: {
          title: '我的项目',
          showTab: true,
          needLogin: true,
          noKeep: true
        }
      },
      {
        path: 'fast',
        name: 'fastReport',
        component: () => import('@/views/hall/fastproject.vue'),
        meta: {
          title: '快速报备',
          needLogin: true,
          noKeep: true
        }
      },
      {
        path: 'newproject',
        name: 'newProject',
        component: () => import('@/views/hall/newproject.vue'),
        meta: {
          title: '新建项目',
          needLogin: true
        }
      },
      {
        path: 'projectdetail',
        name: 'projectDetail',
        component: () => import('@/views/hall/projectdetail.vue'),
        meta: {
          title: '项目详情',
          needLogin: true,
          noKeep: true
        }
      },
      {
        path: 'settings',
        name: 'setting',
        component: () => import('@/views/hall/setting.vue'),
        meta: {
          title: '设置',
          needLogin: true
        }
      },
      {
        path: 'editpwd',
        name: 'resetpassword',
        component: () => import('@/views/hall/resetpassword.vue'),
        meta: {
          title: '重设密码',
          needLogin: true,
          noKeep: true
        }
      },
      {
        path: 'eyasorderlist',
        name: 'eyasorderlist',
        component: () => import('@/views/eyasorder/list.vue'),
        meta: {
          title: '订单列表',
          showTab: true,
          noKeep: true
        }
      },
      {
        path: 'eyasorderdetail',
        name: 'eyasorderdetail',
        component: () => import('@/views/eyasorder/detail.vue'),
        meta: {
          title: '订单详情',
          noKeep: true
        }
      },
      {
        path: 'exheditgoods',
        name: 'exhibitgoods',
        component: () => import('@/views/exhibit/goods.vue'),
        meta: {
          title: '场景商品列表',
          noKeep: true
        }
      },
      {
        path: 'exhibitioneditlist',
        name: 'exhibitionscene',
        component: () => import('@/views/exhibit/scene.vue'),
        meta: {
          title: '展厅概况',
          noKeep: true
        }
      },
      {
        path: 'exhibidetail',
        name: 'exhibitionscenedetail',
        component: () => import('@/views/exhibit/scenedetail.vue'),
        meta: {
          title: '',
          noKeep: true
        }
      },
      {
        path: 'color',
        name: 'color',
        component: () => import('@/views/color/index.vue'),
        meta: {
          title: '色卡明细',
          noKeep: true
        }
      },
      {
        path: 'colordetail',
        name: 'colordetail',
        component: () => import('@/views/color/detail.vue'),
        meta: {
          title: '色卡详情',
          noKeep: true
        }
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

//路由导航守卫
router.beforeEach(async (to, from, next) => {
  // 单页面
  try {
    if (to.path) {
      if (window._hmt) {
        window._hmt.push(['_trackPageview',to.fullPath])
      }
    }

    if (to.path == '/dpjylm/goodslist') {
      to.meta.noKeep = false
    }
    if(to.path == '/' || to.path == '/dpjiahome' || to.path == '/globalHome' || to.path == '/report/index'){
      return next()
    }

    // 首次通过路由进入, 首页做token 获取操作
    let company_route = to.params.company_route

    if (!router.app.$route){
      if (!company_route) {
        return next('/')
      }
      let route_result = await setCompanyRoute(to.params.company_route)
      await changeCompany(route_result.company.id)
      if (route_result.redirect) {
        return router.replace(route_result.redirect)
      }

      let token = await checkToken(to)
      if(token) {
        let newRoute = null
        switch (to.name) {
          case 'login':
          case 'register':
          case 'resetpassword':
          case 'quicklogin':
            newRoute = {
              params: to.params,
              name: 'home'
            }
            // console.log(newRoute)
            break
          default:
            newRoute = null
        }
        // console.log('newRoute', newRoute, to.name)
        // 如果是已经登录的情况并且满足case的情况下跳转到home页面
        if (newRoute) {
          return router.replace(newRoute)
        } else {
          return next()
        }
      }
    } else { // 判断 company_route 是否改变了
      if (to.params.company_route !== from.params.company_route && from.params.company_route ) {
        // console.log('切换公司', from, to)
        await setCompanyRoute(to.params.company_route)
        return next()
      }
    }

    //  url中存在token 2023-1-30 王翀
    if (to.query.token) {
      //  存储token，逻辑存在Token.js
      await NewLoginCallBack(to.query.token)
      //  去除url中token
      let {token, ...rest} = to.query
      return next({ path: to.path, query: rest})
    }
    //  检测是否有登录信息，没有则跳转passport 2023-1-30 王翀
    if (!store.getters.isLogined() && to.meta.needLogin) {
      //  没有login信息，跳转到passport进行登录
      let origin = window.location.href
      let url = `${ENV.passport_url}?com_id=${store.state.companyInfo.id}&app_id=${ENV.id}&redirectUrl=${origin}`
      window.location.href = url
    }

    //  登录时跳转passport 2023-1-30 王翀
    if (to.name == 'login') {
      console.log('====', store.state.companyInfo)
      let current_url = window.location.href
      let url = `${ENV.passport_url}?com_id=${store.state.companyInfo.id}&app_id=${ENV.id}&redirectUrl=${current_url}`
      window.location.href = url
    }


    // 如果company_route 定位失败则走不到这里 这里只对当前要去的路由是否需要登录权限才能查看做处理
    let cfl = checkForLogin(router, to)
    if(cfl) {
      // 要去的页面没有权限 所以跳转到login 界面
      // console.log('ci1',to)
      SetPreRoute(to)
      return next({
        name: 'login', 
        params: {
          company_route
        }
      })
    } else {
      if (to.path == '/dpjylm/goodslist') {
        to.meta.noKeep = false
      }
      return next()
    }
  } catch (error) {
    return next()
  }
})

export default router

export {
  routes
}