/**
 * 获取营销页数据
 * @param {AxiosInstance} rq 
 * @param {Number} id 营销页id
 */
const GetCompanyGoods = async (rq, id) => {
    const res = await rq.get('functions/company/company_goods', {
        params: {
            id
        }
    })
    return res
}

/**
 * 获取营销页详情数据
 * @param {*} rq 
 * @param {Number} id 营销页id
 */
const GetCompanyGoodsShow = async (rq, id) => {
    const res = await rq.get('classes/company_goods_show', {
        params: {
            id
        }
    })
    return res
}

/**
 * 获取营销场景详情数据
 * @param {*} rq 
 * @param {*} params 万能接口参数
 */
const GetExhibition = async (rq, params) => {
    const res = await rq.get('classes/exhibition', {params})
    return res
}

const normalAPIs = {
    GetCompanyGoods,
    GetCompanyGoodsShow,
    GetExhibition
}

const successMessageAPIs = {
}

const fmsNormalAPIs = {

}

const fmsSuccessMessageAPIs = {

}

export default {
    normalAPIs,
    successMessageAPIs,
    fmsNormalAPIs,
    fmsSuccessMessageAPIs
}
