<template lang="pug">
div
  van-field(:label="label" :value='currentTime' 
    :disabled="disabled"
    readonly
    @click=" !disabled ? show= true: ()=> {} "
    v-bind='$attrs')
  van-popup(v-model="show" position="bottom" @close="onClose")
    van-datetime-picker(
      show-toolbar
      v-model="currentDate"
      type="date"
      :min-date="minDate"
      :max-date="maxDate"
      @confirm='confirmDatatime'
      @cancel="show = false"
    )
</template>

<script>
export default {
  name: 'PickerData',
  props: ['value', 'label', 'type'], 
  data() {
    return {
      show: false,
      timeStamp: 0,   // 时间戳
      minDate: new Date(2015, 1, 1),
      maxDate: new Date(2025, 12, 31),
      currentTime: 0,
      currentDate: new Date(),
   
    }
  },
  watch: {
    value(newV) {
      if(newV && newV != '0') {
        this.currentDate = new Date(this.dataFormat(this.value, 'YYYY-MM-DD'))
      } 
    },
    currentDate(v) {
      this.timeStamp = v.valueOf()
      this.currentTime =  this.dataFormat(this.timeStamp, 'YYYY-MM-DD')
    }
  },
  computed: {
    disabled() {
      return this.$attrs.disabled
    }
  },
  methods: {
    confirmDatatime() {
      this.$emit('input', this.timeStamp)
      this.show = false
    },
    onClose() {
      if(!this.value || this.value == '0') {
        this.$emit('input', this.timeStamp)
      }
    },
  },
  mounted() {
    if(!this.value || this.value == '0') {
      // this.currentDate = new Date()
      this.currentTime = ''
    }else {
      this.currentDate = new Date(this.dataFormat(this.value, 'YYYY-MM-DD'))
    }
  }
}
</script>