<template lang="pug">
van-nav-bar(v-bind='$attrs' v-on='$listeners' @click-left="onClickLeft"  @click-right="onClickRight")
</template>
<script>

// title 	标题 	string 	''
// left-text 	左侧文案 	string 	''
// right-text 	右侧文案 	string 	''
// left-arrow 	是否显示左侧箭头 	boolean 	false
// border 	是否显示下边框 	boolean 	true
// fixed 	是否固定在顶部 	boolean 	false
// placeholder v2.5.9 	固定在顶部时，是否在标签位置生成一个等高的占位元素 	boolean 	false
// z-index 	导航栏 z-index 	number | string 	1
// safe-area-inset-top v2.10.13
export default {
  name: 'DpNavBar',
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
    onClickLeft() {
      this.$router.back(-1)
    },

    onClickRight() {
      this.$router.push({name: 'home'})
    }
  }
}
</script>