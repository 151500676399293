<template lang="pug">
div
  van-contact-list(v-model='chosenContactId', 
    :list='list', 
    @add='onAdd', 
    @edit='onEdit',
    @select='onSelect'
    :checked='false')
  // 联系人编辑
  van-popup(v-model='showEdit', position='bottom')
    // van-field(v-model='editingContact.job', label='职务', placeholder='请输入职务' style="margin:0px, 18px, 0px, 18px;height:44px;", clearable label-width="62px")
    van-contact-edit(:contact-info='editingContact', :is-edit='isEdit', @save='onSave', @delete='onDelete')
</template>

<script>
export default {
  name: 'LinkMan',
  props: ['value', 'projectId'],
  data() {
    return {
      chosenContactId: 0,
      isEdit: false,
      showEdit: false,
      editingContact: {},
      list: [
        {
          name: '张三',
          tel: '13000000000',
          job: '',
          id: 0,
        },
      ],
    }
  },
  watch: {
    value(newV) {
      this.list = newV
    }
  },
  methods: {

    // 添加联系人
    onAdd() {
      this.editingContact = { id: 0, name: '', tel: '', job: ''}
      this.isEdit = false
      this.showEdit = true
    },

    // 编辑联系人
    onEdit(item) {
      this.isEdit = true;
      this.showEdit = true;
      this.editingContact = item;
    },

    // 选中联系人
    onSelect() {
      this.showList = false;
    },

    // 保存联系人
    async onSave(info) {
      info.project_poi_projects = this.projectId
      this.showEdit = false;
      this.showList = false;

      if (this.isEdit) {
        this.list = this.list.map((item) =>
          item.id === info.id ? info : item
        );
        if(info.id > 0) {
          try{
            await this.$api.PutLinkMan(info)
          }catch(ex) {
            this.$toast(this.$error(ex))
          }
        }
      } else {
        try{
          let res = await this.$api.PostLinkMan(info)
          info.id = res.id
        }catch(ex) {
          this.$toast(this.$error(ex))
        }
        this.list.push(info);
      }
      this.chosenContactId = info.id;
      this.$emit('refreshLinkman', this.list)
    },

    // 删除联系人
    async onDelete(info) {
      this.showEdit = false;
      this.list = this.list.filter((item) => item.id !== info.id);
      if (this.chosenContactId === info.id) {
        this.chosenContactId = null;
      }
      if(info.id > 0) {
        try{
          await this.$api.DeleteLinkMan(info.id)
        }catch(ex) {
          this.$toast(this.$error(ex))
        }
      }
      this.$emit('refreshLinkman', this.list)
      this.$toast('删除成功')
    },
  

    // onSave() {
    //   this.showEdit = false

    //   if (this.isEdit) {
    //     this.v = this.v.map((item) =>
    //       item.id === this.editingContact.id ? this.editingContact : item
    //     );
    //   } else {
    //     this.v.push(this.editingContact)
    //   }
    //   this.chosenContactId = this.editingContact.id
    //   this.$emit('input', this.v)
    // },
 
  },
  mounted() {
    this.list = this.value
  }
}
</script>

