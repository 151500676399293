import Vue from 'vue'
import dayjs from 'dayjs'

Vue.prototype.dataFormat = function (value,type ="YYYY-MM-DD HH:mm:ss"){
  let result = dayjs(parseInt(value)).format(type)
  return result
}

Vue.filter('dataFormat', (value, formatString ="YYYY-MM-DD HH:mm:ss") => {
  let result = dayjs(parseInt(value)).format(formatString)
  return result
})
