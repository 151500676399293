import flow from './flow/getters'
const getters = {
    ...flow,
    GetConst: state => (type) => {
        return state.constant[type] || {}
    },
    isLogined: state => () => {
        return state.isLogined
    }
}

export default getters
