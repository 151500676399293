// import Vue from 'vue'
import App from './App.vue'
import './plugins'
import router from './routes/index'
import store from './stores'
import './static/css/public.css' /*引入公共样式*/


Vue.config.productionTip = false


router.afterEach(() => {
  window.scrollTo(0, 0);
});

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
