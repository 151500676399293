
import store from '@/stores'
import ENV from '../../local_env.json'

/**
 * 登录接口
 * @param {*} rq 
 * @param {ILogin} param 账号密码
 */
const Login = async (rq, param) => {
    const {username, password} = param
    let params = {
        username,
        password,
        com_id: store.state.companyInfo.id
    }
    let res = await rq.get('users/login', { params: params, headers: { 'X-DP-Token': '' }})
    return res
}

const Register = async (rq, data) => {
    let res = await rq.post('users/signUpBySmsCode', data)
    return res
}

// 修改密码
const ResetPassword = async (rq, data) => {
    rq.defaults.baseURL = ENV.passport_api
    data.app_id = ENV.id
    let res = await rq.put('users/updatePassword', data)
    return res
}

// 重置密码
const ResetPasd = async (rq, data) => {
    let res = await rq.put('users/reset_pasd', data)
    return res
}

/**
 * 
 * @param {*} rq 
 * @param {IQuickLogin} param 快捷登录参数
 */
const QuickLogin = async (rq, param) => {
    const {mobile, code} = param
    let params = {
        mobile,
        code,
        com_id: store.state.companyInfo.id
    }
    let res = await rq.get('users/cloud_login', { params: params, headers: { 'X-DP-Token': '' }})
    return res
}

/**
 * 获取验证码
 * @param {*} rq 
 * @param {IRequestSmsCode} param1 获取验证码参数
 */
const RequestSmsCode = async (rq, param1) => {
    const {type, mobile} = param1
    let params = {
        type,
        mobile
    }
    let res = await rq.get('requestSmsCode/send_sms', { params, headers: {'X-DP-Token': ''}})
    return res
}

/**
 * 获取用户信息
 * @param {*} rq 
 * @param {Number} id 用户id
 */
const GetUserInfo = async (rq, id) => {
    let params = {
        com_id: id,
        app_id: ENV.id
    }
    rq.defaults.baseURL = ENV.passport_api
    let res = await rq.get(`current`, {params})
    return res
}

/**
 * 修改用户信息
 * @param {*} rq 
 * @param {Object} params 用户信息参数
 */
const SetUserInfo = async (rq, params) => {
    let {ui_name, ui_head} = params
    let res = await rq.put('users/info', {
        ui_name,
        ui_head
    })
    return res
}

// 提交合作咨询
const PostConsulting = async (rq,data) => {
    let res = await rq.post(`functions/join_registration/join_registration`,data)
    return res
}

/**
 * 更新token中对应的com_id
 * @param {*} rq 
 * @param {IPutToken} param1 更改token参数
 */
const PutTokenComId = async (rq, param1) => {
    const {token, com_id} = param1
    let tokenRes = await rq.get('classes/tokens', {
        params: {
            where: JSON.stringify({
                token
            }),
            limit: 1
        }
        
    })
    const id = tokenRes.data.items[0].id
    let data = {id, com_id}
    let res = await rq.put(`classes/tokens`,data)
    return res
}

const normalAPIs = {
    Login,
    Register,
    ResetPassword,
    QuickLogin,
    RequestSmsCode,
    GetUserInfo,
    ResetPasd,
    SetUserInfo,
    PostConsulting,
    PutTokenComId
}

const successMessageAPIs = {
}

const fmsNormalAPIs = {

}

const fmsSuccessMessageAPIs = {

}

export default {
    normalAPIs,
    successMessageAPIs,
    fmsNormalAPIs,
    fmsSuccessMessageAPIs
}
