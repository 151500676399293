<template lang="pug">
div
  van-field(:label="label")
    template(#input)
      van-uploader(v-model="v" :after-read="afterRead" 
        @delete='onDelete' 
        :preview-options='previewOptions'
        v-bind='$attrs'
      )
</template>

<script>
export default {
  name: 'UploaderField',
  props: ['value', 'label'],
  data() {
    return {
      v: [],
      previewOptions: {
        closeable: true 
      }
    }
  },
  watch: {
    value(newV) {
      this.v = newV
    }
  },
  methods: {
    async afterRead(file) {
      file.status = 'uploading'
      file.message = '上传中'
      let form = new FormData()
      form.append('files', file.file)
      try {
        let res = await this.$api.UploadFiles(form)
        let result = res.data[0]
        result.status = 'done' 
        result.message = '上传成功'
        file.url = res.data[0].url
        this.$set(this.v, this.v.length-1,result)
        this.$emit('input', this.v)
      } catch(e) {
        file.status = 'faild'
        file.message = '上传失败'
        this.$toast(this.$error(e))
      }
    },
    onDelete() {
      this.$emit('input', this.v)
    }
  },
  mounted() {
    this.v = this.value
  }
}
</script>