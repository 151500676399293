<template lang="pug">
div
  component(v-for="(vf, index) of fields"
    :key="index" 
    :is="vf.component ? vf.component : 'van-field'"
    :label="vf.label" 
    v-model="fields_data[vf.key]" 
    v-bind="vf.attrs" 
    :readonly='!no_edit'
    :disabled='!no_edit'
    @click="(vf.click) ? vf.click($event, vf) : ''"
    clearable
    )
  van-row.footer-button(v-if="no_edit")
    van-button.col(type='info' block round @click="onSubmit" :disabled='commitState') 提交
  van-action-sheet(v-if='showClient' v-model="showClient" title="选择客户" )
    client(:is-component="true" @onSelect="onSelectClient" @onCancel='showClient=false')  
</template>

<script>

import {previewSize} from '../../lib/helpers/OssImageHelper'
import {ImagePreview} from 'vant'
import PickerData from '../form/PickerData'
import PickerField from '../form/PickerField'
import client from '../../views/hall/client'

export default {
  name: 'ContractList',
  props: ['value','no_edit'],
  components: {
    [PickerField.name]: PickerField,
    [PickerData.name]: PickerData,
    client
  },
  data() {
    return {
      previewSize,
      commitState: false,
      has_contract: false,
      showClient: false,
      fields: [
         {
          label: '甲方名称',
          key: 'first_party',
          click: ()=>{
            this.showClient = true
          },
          attrs: {
            // required:true,
            // rules: [
            //   {required: true, message: '请选择甲方名称'}
            // ]
          }
        },
        // { label: '甲方(买方)：', key: 'first_party' },
        { label: '乙方(卖方)：', key: 'second_party' },
        { label: '合同编号：', key: 'code' },
        { label: '合同金额(元)：', key: 'amount' },
        { label: '预付定金：', key: 'payment', },
        { 
          label: '交货时间：', 
          key: 'delivery_time', 
          component: 'PickerData',
          attrs:{
            no_edit: this.noEdit
          }
        },
        { 
          label: '签订时间：', 
          key: 'signed_time',
          component: 'picker-data',
          attrs:{
            no_edit: this.noEdit
          }
        },
        // { label: '预计交货时间：', key: 'anticipated_time' },
        { 
          label: '付款方式：', 
          key: 'paymode',
          component: 'picker-field',
          attrs:{
            no_edit: this.noEdit,
            state_type: 'order_payment_type'
          }
        },
        { 
          key: 'has_bill', label: '需要发票', component: "picker-field",
          attrs: {
            div_constant: {
              '0': "否",
              '1': '是'
            },
            no_edit: this.noEdit
          }
        },
        { label: '备注', key: 'remark', },
        {
          label: '合同附件',
          key: 'urls',
          component: 'UploaderField',
          attrs: {
            deletable: this.no_edit,
            disabled: !this.no_edit
          }
        }
      ],
      fields_data: {
        // id:	"694",
        // code:	"123",
        // name:	"123",
        // first_party:	"冠美集团测试公司",
        // second_party:	"恒林家具",
        // amount:	"70214.00",
        // order_poi_ofc_order:	"1553",
        // signed_time:	"0",
        // delivery_time:	"1556668800000",
        // anticipated_time:	"0",
        // has_bill:	"1",
        // cycle_time:	"0",
        // paymode:	"wechat",
        // payment:	"0.00",
        // create_time:	"1555333377583",
        // update_time:	"1555333377583",
        // mask_delete:	"0",
        // contract_rel_contract_files: [
        //   {content_url: ""},
        //   {content_url: ""}
        // ]
      },
      origin_urls: [], // 数据库原本的合同副本文件
    }
  },
  watch:{
    value(v){
      this.init(v)
    },
    no_edit: {
      immediate: true,
      handler(v) {
        this.fields.map(p=> {
          if(p.attrs) {
            p.attrs.no_edit = v
          }
        })
      }
    }
  },
  computed: {

    payMode() {
      let result =  this.$store.getters.GetConst('order_payment_type')
      return result
    },

    noEdit() {
      return this.no_edit
    },

    comInfo() {
      return  this.$store.state.companyInfo
    },

    sellerId() {
      return this.$store.state.userInfo.seller_poi_sell_users
    },
  },
  mounted() {
    this.init(this.value)
  },
  methods:{
    async onSubmit() {
      const { urls, contract_rel_contract_files, ...rest } = this.fields_data
      const tempIds = urls.map( p => p.id)
      // 多余的数据
      const difFiles = this.origin_urls.filter( p => !tempIds.includes(p.id))
      urls.forEach((file) => {
        file.delete = 'no'
        if (!file.id || parseInt(file.id) == 0) {
          const tempFile = difFiles.pop()
          file.id = tempFile ? tempFile.id : 0
        }
        file.content_url = file.url
      })
      const data = {
        ...rest,
        items: urls.concat(difFiles),
      }
      try {
        this.commitState = true
        // 提交保存 put
        if(this.fields_data.id > 0) {
          await this.$api.PutContract(data)
        } 
        else{
          await this.$api.PostContract(data)
        }
        this.$emit('callback', '')
      } catch(ex) {
        this.$toast(this.$error(ex))
      }
      this.commitState = false
    },

    PreViewImg(img){
      ImagePreview({
        images: [
          img
        ],
         closeable: true,
      })
    },

    init(newV) {
      if(!newV) return
      this.has_contract = newV.id_rel_contract_relation && newV.id_rel_contract_relation.length > 0 && newV.id_rel_contract_relation[0].id_poi_contract
      if(this.has_contract) {
        let data = newV.id_rel_contract_relation[0].id_poi_contract
        this.fields_data = data
        // this.fields_data.has_bill = data.has_bill === '1' ? '是' : '否'
        // this.fields_data.paymode = this.payMode[data.paymode]
        // this.fields_data.create_time = this.dataFormat(data.create_time)
        this.fields_data.urls = (data.contract_rel_contract_files || []).map(p=>{ return { url: p.content_url, id: p.id, delete: 'yes', name: p.name}}) 
      } else {
        // 合同未上传
        this.fields_data = {
          id: 0,
          code:	newV.code,
          name:	'',
          ofc_cus_companys: 0,
          seller_poi_sell_users: this.sellerId,
          remark: '',
          first_party:	newV.buyer,
          second_party:	(this.comInfo || {}).full_name || newV.seller,
          amount:	'',
          order_poi_ofc_order:	newV.id,
          signed_time:	0,
          delivery_time:	newV.delivery_time,
          anticipated_time:	0,
          has_bill:	newV.has_bill,
          cycle_time:	newV.cycle_time,
          paymode:	newV.paymode,
          payment:	newV.payment,
          urls: []
        }
      }
      this.origin_urls = [...this.fields_data.urls]
    },

    onSelectClient(client) {
      this.fields_data.ofc_cus_companys = client.id
      this.fields_data.first_party = client.fullname || client.spname
      this.showClient = false
    },
  }
}
</script>
