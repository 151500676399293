

const PostStatisticsNew = async (rq, data) => {
  let res = await rq.post('classes/statistics_new', data)
  return res
}


const normalAPIs = {
}

const successMessageAPIs = {
  PostStatisticsNew
}

const fmsNormalAPIs = {

}

const fmsSuccessMessageAPIs = {

}

export default {
    normalAPIs,
    successMessageAPIs,
    fmsNormalAPIs,
    fmsSuccessMessageAPIs
}
