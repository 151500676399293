import ENV from '../../local_env.json'

/**
 * 获取商品列表
 * @param {*} rq 
 * @param {*} params 
 * @returns 
 */
const GetGoodsList = async (rq, params) => {
    const res = await rq.get(`${ENV.goods_api}goods/search`, { params })
    return res
}

/**
 * 获取品牌列表
 * @param {*} rq 
 * @param {*} params 
 * @returns 
 */
const GetComBrandsList = async (rq, params) => {
  const res = await rq.get(`${ENV.goods_api}hall/gethalls`, { params, headers: {'X-DP-Token': ''}})
  return res
}

/**
 * 获取销售服务的公司列表
 * @param {*} rq 
 * @param {*} params 
 * @returns 
 */
 const GetSellerService = async (rq, params) => {
  const res = await rq.get(`${ENV.goods_api}hall/seller/gethalls`, { params })
  return res
}

const normalAPIs = {
  GetGoodsList,
  GetComBrandsList,
  GetSellerService
}

const successMessageAPIs = {
}

const fmsNormalAPIs = {

}

const fmsSuccessMessageAPIs = {

}

export default {
    normalAPIs,
    successMessageAPIs,
    fmsNormalAPIs,
    fmsSuccessMessageAPIs
}
