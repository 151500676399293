import ENV from '../../local_env.json'

/**
 * 
 * @param {*} rq 
 * @param {*} form 'actionId,id,flow_remark'
 * @returns 
 */
const UpdateFlowStep = async (rq, form) => {
    let data = { ...form }
    rq.defaults.baseURL = ENV.fmsFlowAPI 
    // rq.defaults.baseURL = '//192.168.1.128:7001/api/v1/'
    let res = await rq.post('flow/execute', data)
    return res
}

const normalAPIs = {
}

const successMessageAPIs = {
    UpdateFlowStep
}

const fmsNormalAPIs = {

}

const fmsSuccessMessageAPIs = {

}

export default {
    normalAPIs,
    successMessageAPIs,
    fmsNormalAPIs,
    fmsSuccessMessageAPIs
}
