<template lang="pug">
div
  van-pull-refresh(
    v-model="refreshing"
    @refresh="onRefresh()"
    )
    div(style="min-height:800px;")
      van-list(
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        )
        slot
</template>

<script>

export default {
  name: 'PullRefreshList',
  props: ['getListMethod'],
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      page:{
        limit: this.$store.state.limit,
        skip: 0
      },
      listLength: 0
    }
  },
  methods: {
    async getListData() {
      let params = {
        limit: this.page.limit,
        skip: this.listLength
      }
      let length = await this.getListMethod(params)
      this.listLength += length
      if (length < this.page.limit) {
        this.finished = true
      }
    },
    refresh() {
      this.refreshing = true
      this.onRefresh()
    },
    onRefresh() {
      // // console.log('onRefresh?')
      // 清空列表数据
      this.finished = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    async onLoad() {
      if (this.refreshing) {
        this.listLength = 0
        this.$emit('update:list', [])
        this.refreshing = false
      }
      await this.getListData()
      this.loading = false
    }
  }
}
</script>