import Vue from 'vue'
import path from 'path'
const requireComponent = require.context('./global/', true, /\.vue$/)

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    const ctrl = componentConfig.default || componentConfig
    // console.log(fileName)
    const ctrlFileName = path.basename(fileName).split('.')[0]
    Vue.component(ctrl.name || ctrlFileName, ctrl)
})