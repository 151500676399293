

// 获取我的收藏
const GetCollect = async (rq, params) => {
    let res = await rq.get('classes/user_preference', {params})
    for (let item of res.data.items) {
        let pm = {
            id: item.point,
            keys: 'fur_image, fur_id, fur_name, sku_poi_furniture_sku, logic_type',
        }
         let fur = await rq.get('classes/furnitures', {params: pm})
         item.furniture = {
             ...fur.data
         }
    }
    // let res = await rq.get('functions/cart/app_preference',{params})
    return res
}

// 添加到我的收藏
const AddToCollect = async (rq, data) => {
    let res = await rq.post('classes/user_preference', data)
    return res
}

// 取消收藏
const RemoveToCollect = async (rq, id) => {
    let data = { id: id }
    let res = await rq.delete('classes/user_preference', { data })
    // let res = await rq.delete('functions/cart/app_preference', {data})
    return res
}

const GetCart = async (rq, param) => {
    let params = {
        ...param,
        customer_id: 0,
    }
    let res = await rq.get('functions/cart/app_cart', {params})
    return res
}
// 加入购物车
const AddToCart = async (rq, data) => {
    let res = await rq.post('functions/cart/app_cart', data)
    return res
}

const RemoveToCart = async (rq, data) => {
    let res = await rq.delete('functions/cart/app_cart_items', {data})
    return res
}

// 获取我的客户列表
const GetCustomer = async (rq, params) => {
    let res = await rq.get('classes/ofc_cus_companys', {params})
    return res
}

// 删除我的客户
const DeleteCustomer = async (rq, params) => {
    let res = await rq.get('classes/pre_ofc_cus_companys',{params})
    return res
}

// 获取我的客户详情
const GetCustomerInfo = async (rq, data) => {
    let res = await rq.get(`classes/ofc_cus_companys?id=${data}`)
    return res
}

// 更新我的客户详情
const PutCustomerInfo = async (rq, params) => {
    let res = await rq.put('functions/app_customer/app_companys/',params)
    return res
}

const PostCustomerInfo = async (rq, params) => {
    let res = await rq.post('functions/app_customer/app_companys/' ,params)
    return res
}


// 获取我的客户联系人
const GetContact = async (rq, data) => {
    let res = await rq.get(`functions/app_customer/app_linkman?customer_id=${data}`)
    return res
}

// 更新我的客户联系人
const PutContact = async (rq, data) => {
    let res = await rq.put(`functions/app_customer/app_linkman?customer_id`, {data})
    return res
}

// 添加我的客户联系人
const PostContact = async (rq, data) => {
    let res = await rq.post(`functions/app_customer/app_linkman`, {data})
    return res
}

// 删除我的客户联系人
const DeleteContact = async (rq, data) => {
    let res = await rq.delete(`classes/pre_ofc_linkman`,{data})
    return res
}

// 获取我的客户联系人地址
const GetContactAddress = async (rq, data) => {
    let res = await rq.get(`functions/app_customer/app_address?customer_id=${data}`)
    return res
}

// 获取我的客户联系人地址
const PutContactAddress = async (rq, data) => {
    let res = await rq.put(`functions/app_customer/app_address?customer_id`, {data})
    return res
}

// 添加我的客户联系人地址
const PostContactAddress = async (rq, data) => {
    let res = await rq.post(`functions/app_customer/app_address`, {data})
    return res
}

// 删除我的客户联系地址
const DeleteContactAddress = async (rq, data) => {
    let res = await rq.delete(`classes/pre_ofc_address`,{data})
    return res
}

// 获取我的收货地址
const GetUserAddress = async (rq, data) => {
    let res = await rq.get(`classes/user_address`, {data})
    return res
}

// 获取我的收货地址
const PutUserAddress = async (rq, data) => {
    let res = await rq.put(`classes/user_address`, data)
    return res
}

// 添加我的收货地址
const PostUserAddress = async (rq, data) => {
    let res = await rq.post(`classes/user_address`, data)
    return res
}

// 删除我的收货地址
const DeleteUserAddress = async (rq, data) => {
    let res = await rq.delete(`classes/user_address`,{data})
    return res
}

// 下单
const CreateAppOrder = async (rq, data) => {
    let res = await rq.post(`functions/seller/app_order`, data)
    return res
}

// 获取提货方式
const GetDeliveryAddress = async (rq, data) => {
    let res = await rq.get(`classes/scm_delivery_type`,data)
    return res
}

// 获取下单优惠规则
const GetFavorAmountConfig = async (rq, data) => {
    let res = await rq.get(`classes/oms_favor_amount_config`,data)
    return res
}

const normalAPIs = {
    GetCollect,
    AddToCollect,
    RemoveToCollect,
    GetCart,
    AddToCart,
    RemoveToCart,
    GetCustomer,
    GetCustomerInfo,
    PostCustomerInfo,
    PutCustomerInfo,
    DeleteCustomer,
    GetContact,
    PutContact,
    PostContact,
    DeleteContact,
    GetContactAddress,
    PutContactAddress,
    PostContactAddress,
    DeleteContactAddress,
    GetUserAddress,
    PutUserAddress,
    PostUserAddress,
    DeleteUserAddress,
    CreateAppOrder,
    GetDeliveryAddress,
    GetFavorAmountConfig
}

const successMessageAPIs = {
}

const fmsNormalAPIs = {

}

const fmsSuccessMessageAPIs = {

}

export default {
    normalAPIs,
    successMessageAPIs,
    fmsNormalAPIs,
    fmsSuccessMessageAPIs
}
