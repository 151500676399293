

/**
 * 
 * @param {*} rq 
 * @param {ICompanyParam} param 公司id和参数
 */
const GetCompanyInfo = async (rq, param) => {
  let res = await rq.get(`classes/companys/${param.id}`, { params: param.params, headers: { 'X-DP-Token': '' }})
  return res
}

/**
 * 
 * @param {*} rq 
 * @param {*} param  获取公司流程
 */
const GetCompanyFlow = async (rq, params) => {
  const res = await rq.get('classes/flow', { params, headers: { 'X-DP-Token': '' } })
  return res
}

const GetCompanyStore = async (rq, id) => {
  let params = {
    where: {
      com_id_poi_companys: id,
      st_stores_states_new: 'sales',
    },
    keys: 'id,st_name'
  }
  let res = await rq.get(`classes/company_stores`, {params})
  return res
}

// 获取用户下的所有销售员信息
const GetSellerUser = async (rq, params) => {
  let res = await rq.get('classes/sell_users', {params, headers: {'X-DP-Token': ''} })
  return res
}

// 获取公司组织
const GetCompanyOrg = async (rq, params) => {
  let res = await rq.get('classes/company_org', {params})
  return res
}


// 生成销售员
const PostSellerUser = async (rq, data) => {
  let res = await rq.post('classes/sell_users', data)
  return res
}

// 更新用户信息
const PutUser = async (rq, data) => {
  let res = await rq.put('users/info', data)
  return res
}

/**
 * 
 * @param {*} rq 
 * @param {ICompanyParam} param 
 */
 const GetCompany = async (rq, params) => {
  let res = await rq.get(`classes/companys`, { params: params, headers: { 'X-DP-Token': '' }})
  return res
}

const normalAPIs = {
  GetCompanyInfo,
  GetCompanyStore,
  GetSellerUser,
  GetCompanyFlow,
  GetCompany,
  GetCompanyOrg
}

const successMessageAPIs = {
  PostSellerUser,
  PutUser
}

const fmsNormalAPIs = {

}

const fmsSuccessMessageAPIs = {

}

export default {
    normalAPIs,
    successMessageAPIs,
    fmsNormalAPIs,
    fmsSuccessMessageAPIs
}
