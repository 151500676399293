<template lang="pug">
div
  van-form(@submit="onConfirm")
    van-field(v-for="(item,index) of field"
      :key="index"
      :label="item.label"
      :required="item.required"
      v-model="data[item.key]"
      :rules='item.rules'
      clearable
      )
    van-button(type="danger" block round native-type='submit') 保存
</template>

<script>
import { Sticky } from 'vant'

export default {
  props: [
    'ispop',
  ],
  components: {
    [Sticky.name]: Sticky,
  },
  data() {
    return {
      field: [
        { label: '客户简称：', key: 'spname', required: true, rules:[{ required: true, message: '请填写客户简称' }]},
        { label: '客户全称：', key: 'fullname', required: true, rules:[{ required: true, message: '请填写客户全称' }]},
        { label: '联系电话：', key: 'tel', required: false},
        { label: '备注：', key: 'remark', required: false},
      ],
      data: {
        id: 0,
        spname: '',
        fullname: '',
        tel: '',
        remark: '',
      }
    }
  },
  watch:{
  },
  computed: {
    userId() {
      return this.$store.state.userInfo.user_poi_users
    },
    sellerId() {
      return this.$store.state.userInfo.seller_poi_sell_users
    },
    sell_users() {
      return this.$store.state.userInfo.sell_users
    },
    companyInfo() {
      return this.$store.state.companyInfo
    },
  },
  methods: {
    async onConfirm() {
      try {
        if(!this.data.spname || !this.data.fullname) {
          this.$toast("请填写必要信息")
          return
        } 
        this.data.id = 0
        this.data.seller_poi_users = this.userId
        this.data.seller_poi_sell_users = this.sellerId
        this.data.org_poi_company_org = await this.getCompanyOrg()
        await this.$api.PostCustomerInfo(this.data)
        this.$toast('添加客户成功')
        if(!this.ispop){
          this.$router.push({name: 'client'})
        }
        else{
          this.$emit('callback')
        }
      } catch (e) {
        this.$toast(this.$error(e))
      }
    },

    async getCompanyOrg() {
      let org_poi_company_org = 0
      if (this.sell_users && this.sell_users.org_poi_company_org && this.sell_users.org_poi_company_org != '0') {
        org_poi_company_org = this.sell_users.org_poi_company_org
      } else {
        const params = {
          where: {
            com_id_poi_companys: this.companyInfo.id
          },
          limit: 1
        }
        let orgRes =  await this.$api.GetCompanyOrg(params)
        org_poi_company_org = orgRes.data.items[0].id
      }
      return org_poi_company_org
    }
  },
  created() {
     this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      onClickLeft: () => {
        this.$router.go(-1)
      }
    })
  },
}
</script>