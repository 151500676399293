
const previewSize = '?x-oss-process=image/resize,h_512,w_512,m_pad,limit_0'

const bannerSize = '?x-oss-process=image/resize,h_280,m_pad,limit_0'

const swipeSize = '?x-oss-process=image/resize,h_280'

const introSize = '?x-oss-process=image/resize,w_760'

export {
    previewSize,
    bannerSize,
    swipeSize,
    introSize
}