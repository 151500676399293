<template lang="pug">
spn
  van-tag(v-if='state == 0' color="#f7f8fa" text-color="#000000" v-bind='$attrs' v-on='$listeners') {{ label }}
  van-tag(v-else-if='state == 1' color="#fde6e9" text-color="#ee0a24" v-bind='$attrs'  v-on='$listeners') {{ label }}
  van-tag(v-else color="#f7f8fa" text-color="#c8c9cc" v-bind='$attrs'  v-on='$listeners') {{ label }}
</template>

<script>
export default {
  name: 'DpSkuItem',
  props: ['label', 'state'],
  data() {
    return {
    }
  },
  watch: {
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style lang="less" scoped>
.van-tag.van-tag--default {
  padding: 3px;
}

</style>