import ENV from '../../local_env.json'

const Upload = async (rq, formData) => {
    let res = await rq.post('upload', formData)
    return res
}

const UploadFiles = async (rq, formData) => {
    const res = await rq.post(`${ENV.fmsFlowAPI}upload`, formData)
    return res
}

const normalAPIs = {
}

const successMessageAPIs = {
    Upload,
    UploadFiles
}

const fmsNormalAPIs = {

}

const fmsSuccessMessageAPIs = {

}

export default {
    normalAPIs,
    successMessageAPIs,
    fmsNormalAPIs,
    fmsSuccessMessageAPIs
}