
const GetHallData = async (rq, comId) => {
  let params = {
    where: {
      com_id_poi_companys: comId
    },
    limit: 1,
    order: '-id',
    keys: 'config,furlist_url'
  }
  let res = await rq.get('classes/company_mobile_skin_logs', { params, headers: { 'X-DP-Token': '' }})
  return res
}


const GetColorCard = async (rq, params) => {
  let res = await rq.get('classes/gms_color', { params })
  return res
}


const normalAPIs = {
  GetHallData,
  GetColorCard
}

const successMessageAPIs = {
}

const fmsNormalAPIs = {

}

const fmsSuccessMessageAPIs = {

}

export default {
    normalAPIs,
    successMessageAPIs,
    fmsNormalAPIs,
    fmsSuccessMessageAPIs
}
