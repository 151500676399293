import config from '../../local_env.json'

// 获取订单新接口
const GetOrderNew = async (rq, params) => {
  let res = await rq.get(`${config.orderAPI}classes/order`, {params})
  return res
}

// 获取报价单
const GetOffer = async (rq, param) => {
  let params = {
    ...param,
    order: "-update_time",
    with: {
      include: [
        {
          table: 'cus_com_poi_ofc_cus_companys',
          keys: 'spname, fullname'
        }
      ],
      relation: [
        {
          table: 'quote_item',
          key: 'quote_poi_quote',
          keys: 'id, quote_poi_quote, thumbnail_url',
          limit: 1,
          needcount: 0
        }
      ]
    },
  }
  let res = await rq.get('classes/quote', {params})
  return res
}

const CreateQuote = async (rq, data) => {
  // let res = await rq.post('functions/quote/quote_new', data)
  const res = await rq.post(`${config.api}functions/quote/quote_new`, data)
  return res
}

/**
 * 
 * @param {*} rq 
 * @param {*} data 更新quote
 */
const UpdateQuote = async (rq, data) => {
  // let res = await rq.put('functions/quote/quote_new', data)
  const res = await rq.put(`${config.api}functions/quote/quote_new`, data)
  return res
}


const GetQuote = async (rq, params) => {
  let res = await rq.get('classes/quote', {params})
  return res
}

// 获取报价单
const GetQuoteNew = async (rq, params) => {
  let {id, ...rest} = params
  const res = await rq.get('classes/quote/' + id, { params: rest })
  return res
}

// 删除报价单
const DeleteQuote = async (rq, id) => {
  const res = await rq.delete('classes/quote/' + id)
  return res
}

const QuoteFlowUpdate = async (rq, data) => {
  let res = await rq.put('/functions/quote/quote_log', data)
  return res
}

// 更新订单
const PutQuote = async (rq, data) => {
  let res = await rq.put('classes/quote', data)
  return res
}

// 获取我的订单
const GetOfcOrder = async (rq, params) => {
  let res = await rq.get('classes/ofc_order', {params})
  return res
}

// 获取订单合同
const GetOfcContract = async (rq, params) => {
  let res = await rq.get('classes/pre_contract', {params})
  return res
}

// 获取报价单历史
const GetQuoteLog = async (rq, id) => {
  let params = {
    where: {
      log_to: id,
    },
    order: '-id',
    with: {
      "relation":[{"table":"quote_item","key":"quote_poi_quote", "needcount": 0,"order":"order"}],
      "include":[{"table":"com_id_poi_companys","keys":"id, com_name, com_addr"},{"table":"cus_com_poi_ofc_cus_companys","keys":"id, spname, fullname"},{"table":"seller_poi_users","keys":"id, username, u_mobile, info_poi_user_info","include":[{"table":"info_poi_user_info","keys":"id, ui_name"}]}]}
    }
  let res = await rq.get('classes/quote', {params})
  return res
}

// 上传合同
const PostContract = async (rq, params) => {
  let res = await rq.post('functions/contract/contract', params)
  return res
}

// 更新合同
const PutContract = async (rq, data) => {
  let res = await rq.put('functions/contract/contract', data)
  return res
}

// 通过关联关系表获取合同
const GetContract = async (rq, id) => {
  let params = {
    where: {
      id_poi_ofc_order: id,
    },
    needcount: 0,
    with: {
      include: [
        {
          table: 'id_poi_contract',
          relation: [
            {
              table: 'contract_files',
              key: 'contract_poi_contract',
              needcount: 0
            }
          ]
        }
      ]
    }
  }
  let res = await rq.get('classes/contract_relation', {params})
  return res
}

// 获取物流信息
const GetLogistics = async (rq, id) => {
  let params = {
    where: {
      order_poi_ofc_order: id,
    },
    needcount: 0,
    with: {
      include:[
          {
              table:"order_poi_ofc_order",
              keys:"number,custom_number,buyer,address,receipt,tel,code"
          }
      ],
      relation:[
          {
              table:"logistics_item",
              key:"logistics_poi_logistics",
              needcount: 0,
              include:[
                  {
                      table:"item_poi_ofc_order_item"
                  }
              ]
          },
          {
              table:"logistics_step",
              key:"logistics_poi_logistics",
              order:"-id",
              needcount: 0,
          }
      ]
    }
  }
  let res = await rq.get('classes/logistics', {params})
  return res
}

// 确认收货
const ConfirmGoods = async (rq, data) => {
  let res = await rq.put('functions/order/confirm_goods', data)
  return res
}

// 获取物流信息
const GetLogisticsNew = async (rq, params) => {
  let res = await rq.get('classes/logistics', {params})
  return res
}

const normalAPIs = {
  GetOrderNew,
  GetOffer,
  GetQuoteLog,
  GetQuote,
  GetContract,
  GetLogistics,
  GetLogisticsNew,
  ConfirmGoods,
  GetQuoteNew,
  GetOfcOrder,
  GetOfcContract
}

const successMessageAPIs = {
  DeleteQuote,
  PostContract,
  PutContract,
  QuoteFlowUpdate,
  PutQuote
 
}

const fmsNormalAPIs = {
}

const fmsSuccessMessageAPIs = {
  CreateQuote,
  UpdateQuote
}

export default {
    normalAPIs,
    successMessageAPIs,
    fmsNormalAPIs,
    fmsSuccessMessageAPIs
}
