import flow from './flow/actions'
const actions = {
    logout ({commit}) {
        commit('setLoginInfo', {
            isLogined: false,
            userInfo: {}
        })
    },
    ...flow
}

export default actions