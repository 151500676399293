import Vue from 'vue'
import {
    Divider,
    Button,
    Tag,
    Row,
    Col,
    Icon,
    Cell,
    CellGroup,
    Swipe,
    SwipeItem,
    GoodsAction,
    GoodsActionIcon,
    GoodsActionButton,
    Lazyload,
    Field,
    Dialog,
    Image,
    Form,
    Checkbox,
    CheckboxGroup,
    Picker,
    Popup,
    Search,
    DropdownMenu,
    DropdownItem,
    Uploader,
    Radio,
    RadioGroup,

    Grid, 
    GridItem,

    PullRefresh,
    List,
    Toast,
    ContactCard,
    ContactList,
    ContactEdit,
    AddressList,
    AddressEdit,
    ActionSheet,
    Area,

    Tab, 
    Tabs,

    Card,
    Empty,
    Rate,
    ImagePreview,
    Steps,
    Step,
    SwipeCell,
    SubmitBar, 
    DatetimePicker,
    Sticky,
    SwitchCell,
    Stepper,
    Collapse,
    CollapseItem,
    Overlay,
    Loading,
    Badge,
    Notify,
    NavBar
} from 'vant'

// Vue.use(Toast)
Vue.prototype.$toast = Toast
Vue.use(Notify)
Vue.use(Badge)
Vue.use(NavBar)
Vue.use(Collapse)
Vue.use(Loading)
Vue.use(Overlay)
Vue.use(CollapseItem)
Vue.use(Sticky)
Vue.use(Stepper)
Vue.use(SwitchCell)
Vue.use(ContactCard)
Vue.use(ContactList)
Vue.use(ContactEdit)
Vue.use(AddressList)
Vue.use(AddressEdit)
Vue.use(ActionSheet)
Vue.use(Area)
Vue.use(Rate)
Vue.use(Radio)
Vue.use(RadioGroup)

Vue.use(Divider)
Vue.use(Button)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Cell)
Vue.use(CellGroup)

Vue.use(Tag)
Vue.use(Row)
Vue.use(Col)
Vue.use(Icon)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(GoodsAction)
Vue.use(GoodsActionIcon)
Vue.use(GoodsActionButton)
Vue.use(Search)
Vue.use(Field)
Vue.use(Form)

Vue.use(Uploader)

Vue.use(Checkbox)
Vue.use(CheckboxGroup)

Vue.use(Popup)
Vue.use(Picker)

Vue.use(DropdownMenu)
Vue.use(DropdownItem)

Vue.use(Grid)
Vue.use(GridItem)

Vue.use(PullRefresh)
Vue.use(List)
Vue.use(Steps)
Vue.use(Step)

Vue.use(SwipeCell)

Vue.use(Image)

Vue.use(Dialog)

Vue.use(Tab)
Vue.use(Tabs)

Vue.use(Card)
Vue.use(Empty)

Vue.use(ImagePreview)
Vue.use(SubmitBar)
Vue.use(DatetimePicker)

Vue.use(Lazyload, {
    LazyCommponent: true
})