<template lang="pug">
div
   van-image(:src='src' @click='onPreview(urls,index)' v-bind='$attrs')
</template>

<script>
import { ImagePreview } from 'vant'

export default {
  name: 'DpImage',
  props: ['src','urls','index'], 
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  data() {
    return {
    }
  },
  methods: {
    onPreview(url, index) {
      if(url) {
        ImagePreview({
          images: url,
          startPosition: index,
          closeable: true
        })
      } else {
        console.log('预览失败,url=', url)
      }
    }
  }
}
</script>