import api from '../../plugins/api'
const actions = {
    async setFlowInfo({ commit, getters }, newFlowName) {
        if (!getters.getFlow(newFlowName)) {
            const params = {
                with: {
                    relation: [
                        {
                            table: 'flow_status',
                            key: 'flow_poi_flow',
                            order: 'order',
                            needcount: 0,
                            where: {
                                deleted_at: null
                            }
                        },
                        {
                            table: 'flow_step',
                            key: 'flow_poi_flow',
                            needcount: 0,
                            where: {
                                deleted_at: null
                            }
                        }
                    ]
                },
                where: {
                    name: newFlowName
                },
                keys: 'id,flow_poi_flow,name',
                needcount: 0
            }
            const flowRes = (await api.GetCompanyFlow(params)).data
            const statusArr = flowRes[0].flow_rel_flow_status
            const stepArr = flowRes[0].flow_rel_flow_step
            const newFlow = {
                [newFlowName]: {
                    status: indexBy(statusArr, 'name'),
                    step: stepArr
                }
            }
            commit('setFlowInfo', newFlow)
        }
    }
}

const indexBy = (arr, key) => {
    return arr.reduce((result, current) => {
        result[current[key]] = current
        return result
    }, {})
}
export default actions
