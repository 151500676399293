import flow from './flow/mutations'

const mutations = {
  ...flow,
  setRouteInfo (state, info) {
    state.routeInfo = {
      ...state.routeInfo,
      ...info
    }
  },

  setCompanyInfo (state, info) {
    state.companyInfo = {
      ...state.companyInfo,
      ...info
    }
  },

  setCompanySelfType (state, type) {
    state.companySelfType = {
      ...type
    }
  },

  setLoginInfo (state, info) {
    state.isLogined = info.isLogined
    state.userInfo = {
      ...state.userInfo,
      ...info.userInfo
    }
  },

  setSellerInfo (state, info) {
    if(info) {
      state.userInfo.sell_users = info,
      state.userInfo.seller_poi_sell_users = info.id
    }
  },

  setHallData (state, data) {
    state.hallData = data
  },

  setHallConfig (state, data) {
    state.hallConfig = data
  },

  setShowPrice (state, value) {
    state.showPrice = value
  },

  setConstant (state, data) {
    let constant = {}
    for (let i = 0; i < data.length; i++) {
      let type = data[i]['state_types']
      let key = data[i]['name']
      let value = data[i]['alias']
      if (!constant[type]) constant[type] = {}
      constant[type][key] = value
    }
    state.constant = constant
  }
}
export default mutations
