
const GetArea = async (rq) => {
    let areaReq = [rq.get('classes/province'), rq.get('classes/city'),rq.get('classes/district')]
    let res = await Promise.all(areaReq)
    let areas = res.map(({data}) => {
        return data.items
    })
    return areas
}

const GetProvince = async (rq, params) => {
    let res = await rq.get('classes/province', {params})
    return res
}

const GetCity = async (rq, params) => {
    let res = await rq.get('classes/city', {params})
    return res
}

const GetDistrict = async (rq, params) => {
    let res = await rq.get('classes/district', {params})
    return res
}


const normalAPIs = {
    GetArea,
    GetProvince,
    GetCity,
    GetDistrict
}

const successMessageAPIs = {
}

const fmsNormalAPIs = {

}

const fmsSuccessMessageAPIs = {

}

export default {
    normalAPIs,
    successMessageAPIs,
    fmsNormalAPIs,
    fmsSuccessMessageAPIs
}
