import Vue from 'vue'
import axios from 'axios'
import router from '../../routes'
import { GetToken } from '@/lib/helpers/Token'
import config from '../../../local_env.json'

import { normalAPIs, successMessageAPIs, fmsNormalAPIs, fmsSuccessMessageAPIs} from './apis'

let instance = null
let api = null


Vue.use({
  install(Vue, option) {
    // 实例化axios,并且设置一些通用信息，例如请求地址
    instance = axios.create({
        baseURL: option.baseURL || '',
        headers: option.headers || {},
    })
    
    // 合并到接口列表
    const APIs = {
      ...normalAPIs,
      ...successMessageAPIs,
      ...fmsNormalAPIs,
      ...fmsSuccessMessageAPIs
    }

    // 接口包装，将axios实例传入接口函数调用网络请求
    const result = {}
    for (const k in APIs) {
      result[k] = async (data) => {
        // 每次请求前获取最新的token
        instance.defaults.headers['X-DP-Token'] = GetToken()
        instance.defaults.baseURL = option.baseURL
        // 网络请求需要捕获错误
        try {
          // 如果在普通接口中
          let reqRes = {}
          if (normalAPIs[k] || successMessageAPIs[k]) {
            instance.defaults.baseURL = config.api
            reqRes = await APIs[k](instance, data)
          } else if (fmsNormalAPIs[k] || fmsSuccessMessageAPIs[k]) { // 如果在fms配置中，使用fms的接口地址
            instance.defaults.baseURL = config.fmsAPI
            const wrapedInstance = {
              async get(url, innerParams) {
                const params = {
                  url,
                  ...innerParams.params
                }
                try {
                  const res = await instance.get('', { params })
                  return res
                } catch (e) {
                  throw e
                }
              },
              async post(url, innerData) {
                try {
                  const res = await instance.post(`?url=${url}`, innerData)
                  return res
                } catch (e) {
                  throw e
                }
              },
              async put(url, innerData) {
                try {
                  const res = await instance.put(`?url=${url}`, innerData)
                  return res
                } catch (e) {
                  throw e
                }
              },
              async delete(url, innerData) {
                try {
                  const res = await instance.delete(`?url=${url}`, innerData)
                  return res
                } catch (e) {
                  throw e
                }
              },
            }
            reqRes = await APIs[k](wrapedInstance, data)
          }
          // reqRes = await APIs[k](instance, data)
          // console.log('error? ', k, reqRes)

          if (successMessageAPIs[k] || fmsSuccessMessageAPIs[k] && (reqRes.data.msg || reqRes.data.message)) {
            // store.commit('alert', {
            //   type: 'success',
            //   message: reqRes.data.msg || reqRes.data.message
            // })
          }
          return reqRes
        } catch (e) {
          // 如果返回结果报错，打印报错信息
          if (e.response) {
            if (e.response.data.message == 'token is invalid') {
              router.replace({
                name: 'login'
              })
            }
            // store.commit('alert', {
            //   type: 'error',
            //   message: e.response.data.msg || e.response.data.message
            // })
            throw e
          } else { // 代码内其他报错
            // store.commit('alert', {
            //   type: 'error',
            //   message: e.message
            // })
            throw new Error(e.message)
          }
        }
      }
    }
    // 注入到Vue的原型，在vue实例中可以通过this.$api调用接口
    api = result
    Vue.prototype.$api = result
  }
  // })
}, {
  baseURL: `${config.api}`,
  headers: {
    'X-DP-ID': config.id,
    'X-DP-Key': config.key
  },
  throwError: true
})

export default api
