<template lang="pug">
div
  van-sticky(v-if="!isComponent")
    van-search(v-model='searchKey', placeholder='请输入搜索关键词' @search="onSearch" shape="round")
    van-dropdown-menu
      van-dropdown-item(v-model='value', :options='option')
      van-dropdown-item(title='筛选', ref='item')
        //- van-switch-cell(v-model='switch1', title='包邮')
        //- van-switch-cell(v-model='switch2', title='团购')
        van-button(block='', type='info', @click='onConfirm') 确认
  pull-refresh-list(
    v-on:update:list="list = $event"
    :getListMethod="getListData"
    ref='list'
    )
    van-cell(
      v-for='item in list' :key='item.id' :name='item.id'
      :title="item.fullname"
      :value="item.spname"
      @click="onClick(item)"
    )
    //- van-card(
    //-   v-for='item in list' :key='item.id' :name='item.id'
    //-   :title='item.spname'
    //-   :desc='item.fullname'
    //-   :num='item.num' 
    //-   :thumb='item.avatar_url	+ previewSize'
    //-   @click="onClick(item)"
    //-   )
        this.$router.push({name: 'newClient'})
    div.footer(v-if='isComponent')
      van-button(round block type='danger'  @click='addClient=true' style='height:40px;' )
        | 添加客户
  van-action-sheet(v-if='addClient' v-model="addClient" title="添加客户")
    newclient(:ispop='true' @callback='onAddCallBack')
</template>

<script>
import { Sticky } from 'vant'
import { previewSize } from '../../lib/helpers/OssImageHelper'
import Newclient from './newclient'

export default {
  props: ['isComponent'],
  components: {
    [Sticky.name]: Sticky,
    Newclient
  },
  data() {
    return {
      addClient: false,
      previewSize,
      refreshing: false,
      loading: false,
      finished: false,
      searchKey: '',
      value: 0,
      switch1: false,
      switch2: false,
      option: [
        { text: '全部客户', value: 0 },
        { text: '新增客户', value: 1 },
        { text: '忠实客户', value: 2 }
      ],
      list: [
      ]
    }
  },
  computed: {
    userId() {
      return this.$store.state.userInfo.user_poi_users
    },
    sellerId() {
      return this.$store.state.userInfo.seller_poi_sell_users
    },
  },
  methods: {
    // 通过弹窗添加的客户
    onAddCallBack() {
      this.$refs.list.refresh()
      this.addClient = false
    },
    onConfirm() {
      this.$refs.item.toggle()
    },
    onSearch() {
      this.$refs.list.refresh()
    },
    async getListData(params) {
      params = {
        ...params,
        order: '-create_time'
      }
      
      try {
        params.where = {
          seller_poi_users: this.userId,
          seller_poi_sell_users: this.sellerId
        }
        let res = await this.$api.GetCustomer(params)
        let clients = res.data.items.map((item) => {
          item.to = {
            name: 'customerinfo',
            query: {
              id: item.id,
            },
          }
          return item
        })
        this.list = this.list.concat(clients)
        return res.data.items.length
      } catch (e) {
        this.$toast(this.$error(e))
        return 0
      }
    },
    onClick(item) {
      if (this.isComponent) {
        this.$emit('onSelect', item)
      } else {
        this.$router.push(item.to)
      }
    },
  },
  created() {
    this.searchKey = this.$route.query.searchKey
  },
  mounted() {
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      rightText: '新建客户',
      onClickLeft: () => {
        this.$router.go(-1)
      },
      onClickRight: () => {
        this.$router.push({name: 'newClient'})
      }
    })
  }
}
</script>
<style scoped>

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  box-sizing: border-box;
  width: 100%;
  padding: 5px 16px;
  background-color: #fff;
}
</style>