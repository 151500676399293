
const GetFurnitures = async (rq, comId) => {
  let params = {
    where: {
      com_id_poi_companys: comId,
      user_poi_users: '0',
      st_id_poi_company_stores: '0',
      dealer_id_poi_company_dealer: '0',
      fur_states: 'up'
    },
    keys: 'id, fur_name,fur_image',
    limit: 20,
    skip: 0
  }
  let res = await rq.get('classes/furnitures', { params })
  return res
}

const GetAllFurnitures = async (rq, params) => {
  let res = await rq.get('functions/cloud/cloud_furnitures', {params})
  return res
}

// 获取系列
const GetSeries = async (rq, comId) => {
  let params = {
    where: {
      com_id_poi_companys: comId,
      switch_state: 'on',
    },
    order: '-order',
    keys: 'id,companys_self_series_name,furniture_material_tags,order'
  }
  let res = await rq.get('classes/companys_self_series', {params})
  return res
}

// 获取品牌
const GetBrands = async (rq, params) => {
  let res = await rq.get('classes/companys_brand', { params })
  return res
}

// 获取商品三级分类
const GetTypes = async (rq, params) => {
  let res = await rq.get('classes/furniture_types', { params })
  return res
}

// 获取风格分类
const GetStyles = async (rq, params) => {
  let res = await rq.get('classes/furniture_styles', { params })
  return res
}

// 获取空间分类
const GetFields = async (rq, params) => {
  let res = await rq.get('classes/furniture_field_types', { params })
  return res
}

// 获取风格、空间、材质等分类
const GetCompanysSelfType = async (rq, params) => {
  let res = await rq.get('classes/companys_self_type', { params })
  return res
}

const GetFurniture = async (rq, params) => {
  let res = await rq.get('classes/furnitures', { params })
  return res
}

const normalAPIs = {
  GetCompanysSelfType,
    GetFurnitures,
    GetAllFurnitures,
    GetSeries,
    GetBrands,
    GetStyles,
    GetTypes,
    GetFields,
    GetFurniture
}

const successMessageAPIs = {
}

const fmsNormalAPIs = {

}

const fmsSuccessMessageAPIs = {

}

export default {
    normalAPIs,
    successMessageAPIs,
    fmsNormalAPIs,
    fmsSuccessMessageAPIs
}
