
const GetFurDetail = async (rq, params) => {
    let res = await rq.get(`functions/app_homepage/app_furnitures`,{params})
    return res
}

const GetFurCollect = async (rq, params) => {
    let res = await rq.get(`classes/pre_user_preference`,{params})
    return res
}

const DelFurCollect = async (rq, data) => {
    let res = await rq.delete(`classes/pre_user_preference`,{data})
    return res
}

/**
 * 获取家具归属品牌和系列
 * @param {*} rq 
 * @param {Number} id 商品id
 */
const GetFurCom = async (rq, id) => {
    let params={
        id,
        keys:'id,com_id_poi_companys,fur_series_id_poi_companys_self_series,fur_name'
    }
    let res = await rq.get(`classes/furnitures`,{params})
    return res
}

// 查询家具
const GetFurForCom = async (rq, id) => {
    // const {com_id,series, st_id} = param
    // let params = {
    //     com_id,
    //     st_id,
    //     series,
    //     limit: 8,
    //     skip: 0,
    //     comprehensive: 'comprehensive',
    // }
    // let res = await rq.get(`functions/cloud/cloud_furnitures`,{params})
    // return res
    // const {id } = param
    // console.log(param)
    let serieParam = {
        where: {
            fur_id_poi_furnitures: id
        },
        keys: 'series_poi_companys_self_series'
    }
    let series = await rq.get(`classes/company_series_relation`,{params:serieParam})
    if (series.data.count == 0) {
        return series
    }
    const serieIds = series.data.items.map(({series_poi_companys_self_series}) => series_poi_companys_self_series)
    const relIds = series.data.items.map(({id}) => id)
    let params = {
        // limit: 10,
        skip: 0,
        where: {
            series_poi_companys_self_series: {
                $in: serieIds,
            },
            id: {
                $nin: relIds
            }
        },
        with: {
            include:[
                {
                    table: "fur_id_poi_furnitures",
                    keys: 'fur_image,fur_name,fur_states'
                }
            ]
        }
    }
    let res = await rq.get(`classes/company_series_relation`,{params})
    return res
}
const normalAPIs = {
    GetFurDetail,
    GetFurCollect,
    DelFurCollect,
    GetFurCom,
    GetFurForCom,
}

const successMessageAPIs = {
}

const fmsNormalAPIs = {

}

const fmsSuccessMessageAPIs = {

}

export default {
    normalAPIs,
    successMessageAPIs,
    fmsNormalAPIs,
    fmsSuccessMessageAPIs
}
