import config from '../../local_env.json'

const GetProjects = async (rq, params) => {
    params = {
        ...params,
        with: {
            include:[
                {table: "province_poi_province",keys: "id,ProvinceName"},
                {table: "city_poi_city", keys:"id,CityName"},
                {table: "district_poi_district",keys:"id,DistrictName"}
            ]
        },
        order: '-update_time'
    }
    
    let res = await rq.get('classes/projects', {params})
    return res
}

const FastCreateProject = async (rq, data) => {
    // let res = await rq.post('functions/report/fast_record', data)
    const res = await rq.post(`${config.api}functions/report/fast_record`, data)
    return res
}

const CreateProject = async (rq, data) => {
    let res = await rq.post('functions/report/project', data)
    return res
}

// 去报备
const PostProjectReport = async (rq, id) => {
    let params= { id : id}
    let res = await rq.post('functions/report/record', params)
    return res
}

const PutProject = async (rq, data) => {
    let res = await rq.put('functions/report/project', data)
    return res
}

// 通过万能接口更新方案
const PutProjectData = async (rq, data) => {
    let res = await rq.put('classes/projects', data)
    return res
}
// 删除项目
const DeleteProject = async (rq, id) => {
    let data = { id : id}
    let res = await rq.delete('classes/projects', {data})
    return res
}


// 撤回项目
const RevokeProject = async (rq, id) => {
    let params= { id : id}
    let res = await rq.post('functions/report/revoke', params)
    return res
}

// 更新报备人信息
const PutReportman = async (rq, data) => {
    let res = await rq.put('classes/project_reportman', data)
    return res
}
// 获取项目详情
const GetProjectDetail = async (rq, data) => {
    let {id, seller_id} = data
    let with1 = {
        relation:[{
            table:"project_reportman",
            key:"project_poi_projects",
            include:[
                { table:"user_poi_reportman" }
            ]
        },
        { table:"project_attachment", key:"project_poi_projects" },
        { table:"project_furniture_types", key:"project_poi_projects", kyes:'name,id'},
        { table:"project_types", key:"project_poi_projects", kyes:'name,id'},
        { 
            table:"project_first_party_linkman",
            key:"project_poi_projects", 
            include:[
                { table:"id_poi_ofc_linkman" }
            ]
        }],
        include:[
            { table:"first_party_province_poi_province"},
            { table:"first_party_city_poi_city"},
            { table:"first_party_district_poi_district"},
            { table:"id_poi_ofc_cus_companys", keys:"id,fullname"},
            { table:"province_poi_province", keys:"id,ProvinceName"},
            { table:"city_poi_city",keys:"id,CityName" },
            { table:"district_poi_district",keys:"id,DistrictName"}
        ]
    }
    let params = {
        id: id,
        with: with1,
        where: {
            seller_poi_sell_users: seller_id
        }
    }
    let res = await rq.get('functions/report/project', {params})
    return res
}

// 获取进度跟踪
const GetProjectTrack = async (rq, params) => {
    let res = await rq.get('classes/project_track', { params })
    return res
}

// 获取进度跟踪
const GetProjectQuoteRelation = async (rq, params) => {
    let res = await rq.get('classes/project_quote_relation', {params})
    return res
}

// 添加进度跟踪
const PostProjectTrack = async (rq, params) => {
    let res = await rq.post('functions/report/project_track', params)
    return res
}

/**
 * 删除方案关联的报价单
 * @param {*} rq 
 * @param {*} id 
 */
const DeleteProjectQuoteRelation = async (rq, id) => {
    const res = await rq.delete('classes/project_quote_relation/' + id)
    return res
}


/**
 * 添加项目关联的报价单
 * @param {*} rq 
 * @param {*} params 
 */
const CreatePorjectQuoteRelation = async (rq, data) => {
    const res = await rq.post('functions/project/project_quote_relation', data)
    return res
}

/**
 * 获取报备记录
 * @param {*} rq 
 * @param {IProjectRecordParam} param1 报备记录参数
 */
const GetProjectRecord = async (rq, param1) => {
    const {id,name} = param1
    let params = {
        order : '-id',
        report_name: name,
        flow_id: 36,
        id,
    }
    let res = await rq.get('functions/report/record', {params})
    return res
}


// 联系人编辑
const PostLinkMan = async (rq, data) => {
    let res = await rq.post('classes/project_first_party_linkman', data)
    return res
}
const PutLinkMan = async (rq, data) => {
    let res = await rq.put('classes/project_first_party_linkman', data)
    return res
}
const DeleteLinkMan = async (rq, id) => {
    let data = {id: id}
    let res = await rq.delete('classes/project_first_party_linkman', {data})
    return res
}


const normalAPIs = {
    GetProjects,
    GetProjectDetail,
    GetProjectTrack,
    GetProjectRecord,
    GetProjectQuoteRelation
}

const successMessageAPIs = {
    RevokeProject,
    PutProjectData,
    PostProjectReport,
    CreateProject,
    DeleteProject,
    PostProjectTrack,
    PutProject,
    PutReportman,
    PostLinkMan,
    PutLinkMan,
    DeleteLinkMan,
    CreatePorjectQuoteRelation,
    DeleteProjectQuoteRelation
}

const fmsNormalAPIs = {

}

const fmsSuccessMessageAPIs = {
    FastCreateProject
}

export default {
    normalAPIs,
    successMessageAPIs,
    fmsNormalAPIs,
    fmsSuccessMessageAPIs
}
