<template lang="pug">
div
  van-card(
    v-for='(good, index) of goodsList'
    :key="index"
    :title='good.fur_name'
    :desc='`${good.color}/${good.sizes}/${good.version}`', 
    :num='good.num',
    :price='good.factory_price',
    :thumb='good.thumbnail_url + previewSize')
</template>

<script>

import {previewSize} from '../../lib/helpers/OssImageHelper'

export default {
  name: 'GoodsList',
  props: ['value'],
  data() {
    return {
      previewSize,
      goodsList: []
    }
  },
  watch:{
    value(){
      this.goodsList = this.value
    }
  },
  mounted() {
    this.goodsList = this.value
  }
}
</script>
