import Cookies from 'js-cookie'
import config from '../../../local_env.json'

const domain = '.dpjia.com'
const port = config.port

import $api from '../../plugins/api'
import store from '../../stores/index'

// 验证登录状态
const checkToken = async (route_to)=> {
  let token = GetToken()
  let tokenid = GetTokenId()
  if (token) {
    let user_poi_users = GetUserID()
    return await SaveToken({token, id: tokenid, user_poi_users})
  } else {
    return false
  }
}

// 切换公司
const changeCompany = async (com_id)=> {
  // console.log('com_id',com_id)
  let token = GetToken()
  let tokenid = GetTokenId()
  if (token && tokenid) {
    try {
      await $api.PutTokenComId({token,com_id:com_id})
      return true
    } catch (error) {
      RemoveAll()
      return false
    }
  } else {
    return false
  }
}

// 设置公司route 
const setCompanyRoute = async (company_route)=> {
  let params = {
    where: {
      route_abb: company_route
    },
    keys: 'id,com_name,full_name,com_tel,mobile,com_owner'
  }
  let result = {
    redirect: {
      name: 'globalHome'
    }
  }
  try { 
    let res = await $api.GetCompany(params)
    if (res.data.items.length > 0) {
      result = {
        route: {
          comId: res.data.items[0].id,
          route: company_route,
        },
        company: {
          ...res.data.items[0]
        }
      }
    } else {
      result = {
        redirect: {
          name: 'globalHome'
        }
      }
    }
  } catch (error) {
    result = {
      redirect: {
        name: 'globalHome'
      }
    }
  } 
  await getCompanyFlow()
  if (!result.redirect) {
    store.commit('setRouteInfo', result.route)
    store.commit('setCompanyInfo', result.company)
    await SetCompanysSelfType(result.company.id)
    $api.GetConstant().then((constant) => {
      store.commit('setConstant', constant)
    })
    await getHallData(result.company)
  }
  return result
}

// 获取当前页面是否需要登录才可以查看
const checkForLogin = (router, route_to)=> {
  return route_to.meta.needLogin && !store.getters.isLogined()
}

const SetPreRoute = ({name, params, query}) => {
  let preRoute = {name, params, query}
  Cookies.set(`dpjia-preurl-${port}`, JSON.stringify(preRoute))
}

const SaveToken = async ({token, id, user_poi_users}) => {
  if (config.environment === 'dev') {
    Cookies.set(`dpjia-hall-token-${port}`, token)
    Cookies.set(`dpjia-hall-tokenid-${port}`, id)
  } else {
    Cookies.set(`dpjia-hall-token-${port}`, token, {domain})
    Cookies.set(`dpjia-hall-tokenid-${port}`, token, {domain})
  }
  Cookies.set(`designer-id-${port}`, user_poi_users)
  try {
    let res = (await $api.GetUserInfo(store.state.routeInfo.comId)).data
    res = {
      ...res,
      ...res.info_poi_user_info,
      seller_poi_sell_users: (res.sell_users || {}).id || 0,
      ...res.sell_users
    }
    store.commit('setLoginInfo', {
      isLogined: true,
      userInfo: res
    })
    return true
  } catch (error) {
    RemoveAll()
    return false
  }
}

//  存储token 2023-1-30 王翀
const NewSaveToken = async (token) => {
  //  存储token
  Cookies.set(`dpjia-hall-token-${port}`, token)
  // if (config.environment === 'dev') {
  //   Cookies.set(`dpjia-hall-token-${port}`, token)
  //   // Cookies.set(`dpjia-hall-tokenid-${port}`, id)
  // } else {
  //   Cookies.set(`dpjia-hall-token-${port}`, token, {domain})
  //   Cookies.set(`dpjia-hall-tokenid-${port}`, token, {domain})
  // }
  //  请求users/current获取用户数据
  try {
    let res = (await $api.GetUserInfo(store.state.companyInfo.id)).data
    res = {
      ...res,
      ...res.info_poi_user_info,
      seller_poi_sell_users: (res.sell_users || {}).id || 0,
      ...res.sell_users
    }
    Cookies.set(`designer-id-${port}`, res.info_poi_user_info.user_poi_users)
    store.commit('setLoginInfo', {
      isLogined: true,
      userInfo: res
    })
    return true
  } catch (error) {
    RemoveAll()
    console.log('error', error)
    return false
  }
} 

// 登录成功的回调
const LoginCallBack = async (vm, data) => {
  await SaveToken(data)
  let preCookie = Cookies.get(`dpjia-preurl-${port}`)
  if (preCookie) {
    let preRoute = JSON.parse(Cookies.get(`dpjia-preurl-${port}`))
    vm.$router.replace(preRoute)
  } else {
    vm.$router.replace({name: 'home'})
  }
}

//  登录 2023-1-27 王翀
const NewLoginCallBack = async (data) => {
  //  通过token
  await NewSaveToken(data)
}

const GetToken = () => {
  return Cookies.get(`dpjia-hall-token-${port}`) || ''
}

const GetTokenId = () => {
  return Cookies.get(`dpjia-hall-tokenid-${port}`) || ''
}

const GetUserID = () => {
  let userId = Cookies.get(`designer-id-${port}`) || ''
  return userId
}

// 设置公司的分类启用配置
const SetCompanysSelfType = async (com_id) => {
  let params = {
    where: {
      com_id_poi_companys: com_id
    },
  }
  try {
    let res = await $api.GetCompanysSelfType(params)
    let result =  res.data.items.length > 0 ? res.data.items[0] : null
    if (result) {
      store.commit('setCompanySelfType', result)
    }
  } catch (error) {
    RemoveAll()
  }
}

const getHallData = async (companyInfo)=> {
  try {
    let mobileSkin = await $api.GetHallData(companyInfo.id)
    store.commit('setHallData', JSON.parse(mobileSkin.data.items[0].config))
    let res = mobileSkin.data.items[0]
    res.furlist_url = res.furlist_url && res.furlist_url.indexOf('b_art_img') == -1 ? res.furlist_url : ''
    store.commit('setHallConfig', res)
  } catch (error) {
    console.log(error, 'hallerror')
  }
}

// 获取流程 需要在这里 添加要获取的流程名称
const getCompanyFlow = async ()=> {
  const names = ['order','quote_state', 'report_state']
  let params = {
    with: {
      relation: [
        {
          table: 'flow_status',
          key: 'flow_poi_flow',
          order: 'order',
          needcount: 0,
          where: {
            deleted_at: null
          }
        },
        {
          table: 'flow_step',
          key: 'flow_poi_flow',
          needcount: 0,
          where: {
              deleted_at: null
          }
        }
      ]
    },
    where: {
      name: {
        $in: names
      }
    },
    keys: 'id,flow_poi_flow,name',
    needcount: 0
  }
  $api.GetCompanyFlow(params).then((item) => {
    names.map((flow_name) => {
      const statusArr = item.data.find(p => p.name == flow_name).flow_rel_flow_status
      const stepArr = item.data.find(p => p.name == flow_name).flow_rel_flow_step
      const newFlow = {  
        [flow_name]: {
          status: indexBy(statusArr, 'name'),
          step: stepArr
        }
      }
      store.commit('setFlowInfo', newFlow)
    })
  })
}

const indexBy = (arr, key) => {
  return arr.reduce((result, current) => {
      result[current[key]] = current
      return result
  }, {})
}

const RemoveAll = () => {
  Cookies.remove(`dpjia-hall-token-${port}`)
  Cookies.remove(`dpjia-hall-tokenid-${port}`)
  Cookies.remove(`dpjia-hall-token-${port}`, {domain})
  Cookies.remove(`dpjia-hall-tokenid-${port}`, {domain})
  Cookies.remove(`designer-id-${port}`)
  Cookies.remove(`dpjia-preurl-${port}`)
} 


export { 
  checkToken,
  checkForLogin,
  setCompanyRoute,
  LoginCallBack,
  NewLoginCallBack,
  SetPreRoute,
  GetTokenId,
  GetToken,
  RemoveAll,
  changeCompany
}
