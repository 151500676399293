/**
 * 获取指定常量
 * @param {*} rq 
 * @returns 
 */
const GetConstant = async (rq) => {
  const names = ['order_payment_type', 'report_projecttype', 'report_valtime', 'order_pay_type']
  let params = {
    where: {
      state_types: {
        $in: names
      }
    },
    needcount: 0
  }
  let res = await rq.get('classes/selectable_states', { params, headers: { 'X-DP-Token': '' } })
  return res.data
}

const normalAPIs = {
    GetConstant
}

const successMessageAPIs = {
}

const fmsNormalAPIs = {

}

const fmsSuccessMessageAPIs = {

}

export default {
    normalAPIs,
    successMessageAPIs,
    fmsNormalAPIs,
    fmsSuccessMessageAPIs
}
