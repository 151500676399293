
const GetWeChat = async (rq, params) => {
  rq.defaults.baseURL = '//api-wx.dpjia.com/'
  let res = await rq.get('wechat/signature', {params})
  return res
}

const normalAPIs = {
  GetWeChat
}

const successMessageAPIs = {
}

const fmsNormalAPIs = {

}

const fmsSuccessMessageAPIs = {

}

export default {
    normalAPIs,
    successMessageAPIs,
    fmsNormalAPIs,
    fmsSuccessMessageAPIs
}
