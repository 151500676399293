import Vue from 'vue'

Vue.use({
    install(vm) {
        vm.prototype.$error = (err) => {
            if (err.response) {
                return err.response.data.message
            } else {
                
                // console.log(err.stack)
                return err.message
            }
        }
    }
})