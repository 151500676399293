<template lang="pug">
div
  van-overlay(:show='value' v-bind='$attrs' v-on='$listeners')  
    van-loading.wrapper(size="18")  加载中...
    //- van-loading.wrapper(color="#0094ff" size="20")  加载中...
</template>

<script>
export default {
  name: 'LoadingView',
  props: ['value'],
  data() {
    return {
    }
  }
}
</script>
<style scoped lang="less">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>