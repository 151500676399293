<template lang="pug">
#app(v-wechat-title="$route.meta.title")
  <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0,user-scalable=no"/> 
  keep-alive
    router-view(v-if='!$route.meta.noKeep')
  router-view(v-if='$route.meta.noKeep')
</template>

<script>

export default {
  name: 'App'
}

</script>

<style>
body {
  font-size: 16px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
}
</style>
