
const GetEyasOrders = async (rq, params) => {
    let res = await rq.get('functions/eyas_production_management/eyas_order', {params})
    return res
}

const GetEyasOrderDetail = async (rq, params) => {
    let res = await rq.get('functions/eyas_production_management/eyas_order', {params})
    return res
}

const GetEyasOrderItem = async (rq, id) => {
    let res = await rq.get(`functions/eyas_production_management/eyas_order_item?id=${id}`)
    return res
}



const normalAPIs = {
    GetEyasOrders,
    GetEyasOrderDetail,
    GetEyasOrderItem
}

const successMessageAPIs = {
}

const fmsNormalAPIs = {

}

const fmsSuccessMessageAPIs = {

}

export default {
    normalAPIs,
    successMessageAPIs,
    fmsNormalAPIs,
    fmsSuccessMessageAPIs
}
