

// 获取日志
const GetFlowLog = async (rq, params) => {
    const res = await rq.get('classes/flow_log', { params })
    return res
}

// 获取日志
const PostWorkerLog = async (rq, data) => {
    const res = await rq.post('functions/mes/worker_log', data)
    return res
}

// 获取日志二维码内的配置内容
const GetWorkerLogQrInfo = async (rq, params) => {
    const res = await rq.get('functions/mes/worker_log_qr_info', {params})
    return res
}


const normalAPIs = {
    GetFlowLog,
    GetWorkerLogQrInfo
}

const successMessageAPIs = {
    PostWorkerLog
}

const fmsNormalAPIs = {

}

const fmsSuccessMessageAPIs = {

}

export default {
    normalAPIs,
    successMessageAPIs,
    fmsNormalAPIs,
    fmsSuccessMessageAPIs
}
