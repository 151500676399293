<template lang="pug">
div
  van-field(:label="label" :value="v.label" clickable 
    :disabled="disabled"
    readonly
    v-bind='$attrs'
     @click="onClick")
  van-popup(v-model="show" position="bottom")
    van-picker(
      show-toolbar
      :columns="columns"
      @confirm="onConfirm"
      @cancel="show = false"
    )
</template>

<script>
export default {
  name: 'PickerField',
  props: ['value', 'label', 'div_constant', 'state_type'], // div_constant 自定义可选对像 
  data() {
    return {
      show: false,
      v: {
        label: '',
        value: ''
      }
    }
  },
  computed: {
    constant() {
      if(this.div_constant) {
        return this.div_constant
      }
      let result = this.$store.getters.GetConst(this.state_type)
      return result
    },
    columns() {
      return Object.values(this.constant)
    },
    disabled() {
      return this.$attrs.disabled
    }
  },
  watch: {
    value(newV) {
      for (let i in this.constant) {
        if (newV === i) {
          this.v = {
            label: this.constant[i],
            value: newV
          }
        }
      }
    }
  },
  methods: {
    onConfirm(label) {
      let value = ''
      for(let i in this.constant) {
        if (this.constant[i] === label) {
          value = i
        }
      }
      this.v.label = label
      this.$emit('input', value)
      this.show = false
    },

    onClick() {
      if (!this.disabled) {
        this.show = true
      }
    }
  },
  mounted() {
    // 默认选中第一个
    for (let i in this.constant) {
      // if(!this.value) {
      //   this.v = {
      //     label: this.constant[i],
      //     value: i,
      //   }
      //   return 
      // }
      if (this.value === i) {
        this.v = {
          label: this.constant[i],
          value: this.value
        }
      }
    }
  
  }
}
</script>