import flow from './flow/states'
const states = {
    ...flow,
    routeInfo: {
        theme: 'default',
        route: '',
        comId: 0,
    },
    companyInfo: {
        id: 0,
        com_name: '',
        full_name: ''
    },
    companySelfType: {

    },
    isLogined: false,
    userInfo: {
        
    },
    hallData: {
        
    },
    hallConfig: {
        
    },
    constant: {
        
    },
    province: [],
    city: [],
    district: [],
    limit: 20
}

export default states
