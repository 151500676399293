
import Area from '../../api/Area.js'
import Common from '../../api/Common.js'
import Company from '../../api/Company.js'
import Constant from '../../api/Constant.js'
import Exhibition from '../../api/Exhibition.js'
import EyasOrder from '../../api/EyasOrder.js'
import Furniture from '../../api/Furniture.js'
import Hall from '../../api/Hall.js'
import Home from '../../api/Home.js'
import Order from '../../api/Order.js'
import Project from '../../api/Project.js'
import Service from '../../api/Service.js'
import User from '../../api/User.js'
import UserAssets from '../../api/UserAssets.js'
import Wechat from '../../api/Wechat.js'
import flow from '../../api/flow.js'
import goods from '../../api/goods.js'
import upload from '../../api/upload.js'
const normalAPIs = {
  ...Area.normalAPIs, ...Common.normalAPIs, ...Company.normalAPIs, ...Constant.normalAPIs, ...Exhibition.normalAPIs, ...EyasOrder.normalAPIs, ...Furniture.normalAPIs, ...Hall.normalAPIs, ...Home.normalAPIs, ...Order.normalAPIs, ...Project.normalAPIs, ...Service.normalAPIs, ...User.normalAPIs, ...UserAssets.normalAPIs, ...Wechat.normalAPIs, ...flow.normalAPIs, ...goods.normalAPIs, ...upload.normalAPIs
}
const successMessageAPIs = {
  ...Area.successMessageAPIs, ...Common.successMessageAPIs, ...Company.successMessageAPIs, ...Constant.successMessageAPIs, ...Exhibition.successMessageAPIs, ...EyasOrder.successMessageAPIs, ...Furniture.successMessageAPIs, ...Hall.successMessageAPIs, ...Home.successMessageAPIs, ...Order.successMessageAPIs, ...Project.successMessageAPIs, ...Service.successMessageAPIs, ...User.successMessageAPIs, ...UserAssets.successMessageAPIs, ...Wechat.successMessageAPIs, ...flow.successMessageAPIs, ...goods.successMessageAPIs, ...upload.successMessageAPIs
}

const fmsNormalAPIs = {
  ...Area.fmsNormalAPIs, ...Common.fmsNormalAPIs, ...Company.fmsNormalAPIs, ...Constant.fmsNormalAPIs, ...Exhibition.fmsNormalAPIs, ...EyasOrder.fmsNormalAPIs, ...Furniture.fmsNormalAPIs, ...Hall.fmsNormalAPIs, ...Home.fmsNormalAPIs, ...Order.fmsNormalAPIs, ...Project.fmsNormalAPIs, ...Service.fmsNormalAPIs, ...User.fmsNormalAPIs, ...UserAssets.fmsNormalAPIs, ...Wechat.fmsNormalAPIs, ...flow.fmsNormalAPIs, ...goods.fmsNormalAPIs, ...upload.fmsNormalAPIs
}
const fmsSuccessMessageAPIs = {
  ...Area.fmsSuccessMessageAPIs, ...Common.fmsSuccessMessageAPIs, ...Company.fmsSuccessMessageAPIs, ...Constant.fmsSuccessMessageAPIs, ...Exhibition.fmsSuccessMessageAPIs, ...EyasOrder.fmsSuccessMessageAPIs, ...Furniture.fmsSuccessMessageAPIs, ...Hall.fmsSuccessMessageAPIs, ...Home.fmsSuccessMessageAPIs, ...Order.fmsSuccessMessageAPIs, ...Project.fmsSuccessMessageAPIs, ...Service.fmsSuccessMessageAPIs, ...User.fmsSuccessMessageAPIs, ...UserAssets.fmsSuccessMessageAPIs, ...Wechat.fmsSuccessMessageAPIs, ...flow.fmsSuccessMessageAPIs, ...goods.fmsSuccessMessageAPIs, ...upload.fmsSuccessMessageAPIs
}

export {
  normalAPIs,
  successMessageAPIs,
  fmsNormalAPIs,
  fmsSuccessMessageAPIs
}
